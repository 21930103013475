var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
      attrs: { id: "wrap" },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "100px" } },
        [
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "基本信息", id: "basicInfo" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContentTop x-x" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "优惠券名称", prop: "couponCaseName" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputWidthM",
                          attrs: {
                            size: "mini",
                            maxlength: "20",
                            disabled: "",
                          },
                          model: {
                            value: _vm.form.couponCaseName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "couponCaseName", $$v)
                            },
                            expression: "form.couponCaseName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "编号规则", prop: "couponNoRule" } },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "inputWidthM",
                            attrs: {
                              size: "mini",
                              maxlength: "40",
                              readonly: "",
                            },
                            model: {
                              value: _vm.form.couponNoRule,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "couponNoRule", $$v)
                              },
                              expression: "form.couponNoRule",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-more el-input__icon",
                              attrs: { slot: "suffix" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleRuleClick(
                                    _vm.form.couponNoRule
                                  )
                                },
                              },
                              slot: "suffix",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "总张数", prop: "couponCount" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidthM",
                          attrs: { size: "mini", maxlength: "8", disabled: "" },
                          model: {
                            value: _vm.form.couponCount,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "couponCount", $$v)
                            },
                            expression: "form.couponCount",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "tableContentTop x-x" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售张数", prop: "sellCount" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidthM",
                          attrs: { size: "mini", maxlength: "8", disabled: "" },
                          model: {
                            value: _vm.form.sellCount,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "sellCount", $$v)
                            },
                            expression: "form.sellCount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "已领张数", prop: "drawCount" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidthM",
                          attrs: { size: "mini", maxlength: "8", disabled: "" },
                          model: {
                            value: _vm.form.drawCount,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "drawCount", $$v)
                            },
                            expression: "form.drawCount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "作废张数", prop: "scrapCount" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidthM",
                          attrs: { size: "mini", maxlength: "8", disabled: "" },
                          model: {
                            value: _vm.form.scrapCount,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "scrapCount", $$v)
                            },
                            expression: "form.scrapCount",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.form.couponCaseType == "3"
                      ? _c(
                          "el-form-item",
                          {
                            attrs: { label: "卡密长度", prop: "couponPwdLen" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "couponPwdLen",
                              attrs: {
                                size: "mini",
                                placeholder: "长度范围4~10",
                                disabled:
                                  _vm.form.detailData &&
                                  _vm.form.detailData.length,
                              },
                              on: { change: _vm.changeCouponPwdLen },
                              model: {
                                value: _vm.form.couponPwdLen,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "couponPwdLen", $$v)
                                },
                                expression: "form.couponPwdLen",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            [
              _c("template", { slot: "leftCardTitle" }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: { click: _vm.handleTableDetailClick },
                      },
                      [_vm._v("添加优惠券")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: { click: _vm.handleLookDetail },
                      },
                      [_vm._v("查看生成优惠券")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push("/marketing/coupon/Case")
                          },
                        },
                      },
                      [_vm._v("退出")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContent marL15" },
                  [
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-table",
                          {
                            staticClass: "marT10",
                            attrs: {
                              data: _vm.form.detailData,
                              height: _vm.tableHeight,
                              border: "",
                            },
                            on: { "row-click": _vm.handleRowClick },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { width: "55", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: scope.row },
                                          model: {
                                            value: _vm.templateItem,
                                            callback: function ($$v) {
                                              _vm.templateItem = $$v
                                            },
                                            expression: "templateItem",
                                          },
                                        },
                                        [_vm._v(_vm._s(""))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "开始流水号",
                                align: "center",
                                prop: "begGlideNo",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "结束流水号",
                                align: "center",
                                prop: "endGlideNo",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "张数",
                                align: "center",
                                prop: "addCount",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "定义人员",
                                align: "center",
                                prop: "createBy",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "定义日期",
                                align: "center",
                                prop: "createTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", {
        attrs: {
          title: "编号规则",
          width: 450,
          showDialog: _vm.dialogCouponRule,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogCouponRule = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogCouponRule = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingCouponRule,
                        expression: "loadingCouponRule",
                      },
                    ],
                    ref: "couponRule",
                    attrs: { model: _vm.couponRuleForm, "label-width": "70px" },
                  },
                  [
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.couponRuleForm.case,
                              callback: function ($$v) {
                                _vm.$set(_vm.couponRuleForm, "case", $$v)
                              },
                              expression: "couponRuleForm.case",
                            },
                          },
                          [_vm._v("方案编号 ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.couponRuleForm.flow,
                              callback: function ($$v) {
                                _vm.$set(_vm.couponRuleForm, "flow", $$v)
                              },
                              expression: "couponRuleForm.flow",
                            },
                          },
                          [_vm._v("流水码 ")]
                        ),
                      ],
                      1
                    ),
                    _c("el-form-item", [
                      _c(
                        "div",
                        { staticClass: "ruleDiv" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.couponRuleForm.year,
                                callback: function ($$v) {
                                  _vm.$set(_vm.couponRuleForm, "year", $$v)
                                },
                                expression: "couponRuleForm.year",
                              },
                            },
                            [_vm._v("年份 ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("el-form-item", [
                      _c(
                        "div",
                        { staticClass: "ruleDiv" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.couponRuleForm.check,
                                callback: function ($$v) {
                                  _vm.$set(_vm.couponRuleForm, "check", $$v)
                                },
                                expression: "couponRuleForm.check",
                              },
                            },
                            [_vm._v("校验码 ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("el-form-item", [
                      _c(
                        "div",
                        { staticClass: "ruleDiv" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.couponRuleForm.customize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.couponRuleForm, "customize", $$v)
                                },
                                expression: "couponRuleForm.customize",
                              },
                            },
                            [_vm._v("自定义码 ")]
                          ),
                          _c("el-input", {
                            staticStyle: {
                              width: "170px",
                              "margin-left": "10px",
                            },
                            attrs: {
                              disabled: !_vm.couponRuleForm.customize,
                              maxlength: "4",
                              oninput: "value=value.replace(/^0|[^0-9]/g,'')",
                              placeholder: "自定义码",
                              size: "mini",
                            },
                            model: {
                              value: _vm.couponRuleForm.customizeNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.couponRuleForm,
                                  "customizeNumber",
                                  $$v
                                )
                              },
                              expression: "couponRuleForm.customizeNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handleConfirmCouponRule },
                  },
                  [_vm._v("确定")]
                ),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.dialogCouponRule = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("c-dialog", {
        attrs: {
          title: "添加优惠券",
          width: 700,
          showDialog: _vm.dialogAddCoupon,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogAddCoupon = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogAddCoupon = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingAddCouponForm,
                        expression: "loadingAddCouponForm",
                      },
                    ],
                    ref: "addCouponForm",
                    attrs: { model: _vm.addCouponForm, "label-width": "120px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "x-x" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "单据编号" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: { disabled: "", size: "mini" },
                              model: {
                                value: _vm.addCouponForm.couponCaseNo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addCouponForm,
                                    "couponCaseNo",
                                    $$v
                                  )
                                },
                                expression: "addCouponForm.couponCaseNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "单据日期" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: { disabled: "", size: "mini" },
                              model: {
                                value: _vm.addCouponForm.createTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addCouponForm, "createTime", $$v)
                                },
                                expression: "addCouponForm.createTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-x" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "优惠券类型" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: { disabled: "", size: "mini" },
                              model: {
                                value: _vm.addCouponForm.couponCaseTypeName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addCouponForm,
                                    "couponCaseTypeName",
                                    $$v
                                  )
                                },
                                expression: "addCouponForm.couponCaseTypeName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "有效日期" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: { disabled: "", size: "mini" },
                              model: {
                                value: _vm.addCouponForm.validEndTime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addCouponForm,
                                    "validEndTime",
                                    $$v
                                  )
                                },
                                expression: "addCouponForm.validEndTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-x" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "开始流水号" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: { disabled: "", size: "mini" },
                              model: {
                                value: _vm.addCouponForm.begGlideNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addCouponForm, "begGlideNo", $$v)
                                },
                                expression: "addCouponForm.begGlideNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "结束流水号" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: { disabled: "", size: "mini" },
                              model: {
                                value: _vm.addCouponForm.endGlideNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addCouponForm, "endGlideNo", $$v)
                                },
                                expression: "addCouponForm.endGlideNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-x" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "面值" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: { disabled: "", size: "mini" },
                              model: {
                                value: _vm.addCouponForm.parValue,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addCouponForm, "parValue", $$v)
                                },
                                expression: "addCouponForm.parValue",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "入库张数" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: {
                                placeholder: "入库张数",
                                oninput: "value=value.replace(/^0|[^0-9]/g,'')",
                                size: "mini",
                              },
                              on: { input: _vm.addCountInput },
                              model: {
                                value: _vm.addCouponForm.addCount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addCouponForm, "addCount", $$v)
                                },
                                expression: "addCouponForm.addCount",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-x" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "定义人员" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "170px" },
                              attrs: { disabled: "", size: "mini" },
                              model: {
                                value: _vm.addCouponForm.createBy,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addCouponForm, "createBy", $$v)
                                },
                                expression: "addCouponForm.createBy",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handleConfirmAddCoupon },
                  },
                  [_vm._v("确定")]
                ),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.dialogAddCoupon = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }