<template>
  <!-- 优惠券明细 -->
  <div>
    <!-- 头部定位区域 -->
    <topOperatingButton
      :showAudit="false"
      :isSubmitAddBtn="false"
      :isAuditBillBtn="false"
      @submitForm="submitForm(false)"
      @getQuit="getQuit"
      :disabled="isDisabled"
      id="topOperatingButton"
    >
    </topOperatingButton>
    <div
      class="discount-scheme-detail"
      v-loading="boxLoading"
      element-loading-text="数据加载中"
    >
      <div class="main">
        <el-form ref="form" :model="form" label-width="130px" :rules="rules">
          <cardTitleCom cardTitle="基本信息">
            <template slot="cardContent">
              <!-- 基本信息区域 -->
              <div class="basicInfo">
                <!-- 基本信息顶部区域 -->
                <div class="basicInfoTop">
                  <!-- 优惠券左边图片 -->
                  <div class="left">
                    <!-- 优惠券图片 -->
                    <div class="imgTop">
                      <!-- 优惠券背景颜色 -->
                      <div
                        class="discountImg"
                        :class="{
                          lightGreen: form.colorType === '1',
                          darkGreen: form.colorType === '2',
                          cambridgeBlue: form.colorType === '3',
                          darkBlue: form.colorType === '4',
                          purple: form.colorType === '5',
                          brown: form.colorType === '6',
                          yellow: form.colorType === '7',
                          orange: form.colorType === '8',
                          reddish: form.colorType === '9',
                          red: form.colorType === '10',
                        }"
                      >
                        <div class="imgLeft">
                          <div class="content">
                            <!-- 代金券 -->
                            <div v-if="form.couponCaseType === '0'">
                              {{ `减${form.parValue || "0"}元` }}
                            </div>
                            <!-- 折扣券 -->
                            <div v-if="form.couponCaseType === '1'">
                              {{ `打${form.parValue || "0"}折` }}
                            </div>
                            <!-- 抵扣券 -->
                            <div v-if="form.couponCaseType === '2'">
                              {{ `抵扣后${form.parValue || "0"}元` }}
                            </div>
                            <!-- 充值券 -->
                            <div v-if="form.couponCaseType === '3'">
                              {{ `${form.parValue || "0"}元` }}
                            </div>
                            <div class="tag x-bc">
                              <span>{{ form.couponCaseName || "国庆限时活动" }}</span>
                              <span v-if="form.sillType === '0'">无门槛</span>
                              <span v-if="form.sillType === '1'">{{
                                `满${form.sillMoney || "0"}元可使用`
                              }}</span>
                            </div>
                            <div class="tag">
                              {{
                                `${form.validBegTime || "2023-01-01 00:00:00"} ~ ${
                                  form.validEndTime || "2024-01-01 00:00:00"
                                }`
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="imgRight">
                          <div class="text">立即领取</div>
                        </div>
                      </div>
                    </div>
                    <div class="colorBase x-f">
                      <div class="colorLeft">选择卡券颜色</div>
                      <div class="colorRight">
                        <div class="coloritem lightGreen" @click="setCardBg('1')"></div>
                        <div class="coloritem darkGreen" @click="setCardBg('2')"></div>
                        <div
                          class="coloritem cambridgeBlue"
                          @click="setCardBg('3')"
                        ></div>
                        <div class="coloritem darkBlue" @click="setCardBg('4')"></div>
                        <div class="coloritem purple" @click="setCardBg('5')"></div>
                        <div class="coloritem brown" @click="setCardBg('6')"></div>
                        <div class="coloritem yellow" @click="setCardBg('7')"></div>
                        <div class="coloritem orange" @click="setCardBg('8')"></div>
                        <div class="coloritem reddish" @click="setCardBg('9')"></div>
                        <div class="coloritem red" @click="setCardBg('10')"></div>
                      </div>
                    </div>
                  </div>
                  <!-- 优惠券右边信息区域 -->
                  <div class="right">
                    <el-form-item label="优惠券方案编号" prop="couponCaseNo">
                      <el-input
                        disabled
                        size="mini"
                        placeholder="自动生成"
                        v-model="form.couponCaseNo"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="优惠券方案名称" prop="couponCaseName">
                      <el-input size="mini" v-model="form.couponCaseName"></el-input>
                    </el-form-item>
                    <el-form-item label="优惠券形式" prop="couponMode">
                      <el-select
                        size="mini"
                        v-model="form.couponMode"
                        placeholder="请选择"
                        :disabled="disCouponMode"
                      >
                        <el-option
                          v-for="dict in dict.type.coupon_mode"
                          :key="dict.value"
                          :label="dict.label"
                          :value="dict.value"
                        />
                      </el-select>
                    </el-form-item>
                    <!-- <el-form-item
                      label="券码方式"
                      v-if="form.couponMode == '1'"
                    >
                      <el-radio-group v-model="form.couponNoCreateMode">
                        <el-radio :label="0">自动生成</el-radio>
                        <el-radio :label="1">导入券码</el-radio>
                      </el-radio-group>
                    </el-form-item> -->
                    <!-- 代金券 -->
                    <el-form-item
                      label="面值"
                      v-if="form.couponCaseType === '0' || form.couponCaseType === '3'"
                      prop="parValue"
                    >
                      <el-input
                        class="faceValue"
                        placeholder="请输入"
                        size="mini"
                        v-model="form.parValue"
                      ></el-input>
                      <span>元</span>
                    </el-form-item>
                    <!-- 折扣券 -->
                    <el-form-item
                      label="折扣(%)"
                      v-if="form.couponCaseType === '1'"
                      prop="parValue"
                    >
                      <el-input
                        class="faceValue"
                        placeholder="请输入"
                        min="1"
                        max="10"
                        size="mini"
                        v-model="form.parValue"
                      ></el-input>
                    </el-form-item>
                    <!-- 抵扣券 -->
                    <el-form-item
                      label="金额"
                      v-if="form.couponCaseType === '2'"
                      prop="parValue"
                    >
                      <el-input
                        class="faceValue"
                        placeholder="请输入"
                        size="mini"
                        v-model="form.parValue"
                      ></el-input>
                      <span>元</span>
                      <span class="tag">(抵扣后需支付的金额)</span>
                    </el-form-item>
                    <div class="threshold">
                      <el-form-item label="使用门槛" prop="sillType">
                        <el-radio-group v-model="form.sillType" class="x-f">
                          <el-radio label="0">无使用门槛</el-radio>
                          <el-radio label="1" v-if="form.couponCaseType != '2'"
                            >适用商品满</el-radio
                          >
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item
                        class="thresholdAmount"
                        v-if="form.couponCaseType != '2' && form.sillType === '0'"
                      >
                        <el-input size="mini" disabled></el-input>
                      </el-form-item>
                      <el-form-item
                        class="thresholdAmount"
                        v-if="form.couponCaseType != '2' && form.sillType === '1'"
                        prop="sillMoney"
                      >
                        <el-input
                          placeholder="请输入"
                          size="mini"
                          v-model="form.sillMoney"
                        ></el-input>
                      </el-form-item>
                      <div class="text" v-if="form.couponCaseType != '2'">元</div>
                      <!-- 代金券方案 -->
                      <div class="giftCard" v-if="form.couponCaseType === '0'">
                        <div class="tag" v-if="form.sillType === '0'">
                          {{ `(无门槛,减${form.parValue || "xx"}元)` }}
                        </div>
                        <div class="tag" v-if="form.sillType === '1'">
                          {{
                            `(满${form.sillMoney || "xx"}元,减${form.parValue || "xx"}元)`
                          }}
                        </div>
                      </div>
                      <!-- 折扣券方案 -->
                      <div class="discountCard" v-if="form.couponCaseType === '1'">
                        <div class="tag" v-if="form.sillType === '0'">
                          {{ `(无门槛,打${form.parValue || "xx"}折)` }}
                        </div>
                        <div class="tag" v-if="form.sillType === '1'">
                          {{
                            `(满${form.sillMoney || "xx"}元,打${form.parValue || "xx"}折)`
                          }}
                        </div>
                      </div>
                    </div>
                    <el-form-item label="有效时间" prop="validTime">
                      <div class="x-f">
                        <el-date-picker
                          v-model="form.validTime"
                          size="mini"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          :default-time="['00:00:00', '23:59:59']"
                          :picker-options="forbiddenTime"
                        >
                        </el-date-picker>
                        <div class="tag">(优惠券有效时间)</div>
                      </div>
                    </el-form-item>
                    <el-form-item>
                      <div class="specialTime">
                        <el-checkbox v-model="form.isSpecalTime"
                          >特殊领用时间</el-checkbox
                        >
                        <div>
                          领取后<el-input size="mini" v-model="form.drawDay"></el-input
                          >天生效,生效后有效时长
                        </div>
                        <div>
                          <el-input size="mini" v-model="form.validDay"></el-input>
                          天
                        </div>
                      </div>
                    </el-form-item>
                    <el-form-item label="优惠券说明">
                      <el-input
                        type="textarea"
                        placeholder="请输入说明"
                        size="mini"
                        v-model="form.couponDesc"
                        maxlength="300"
                        show-word-limit
                        :rows="3"
                      >
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
                <!-- 基本信息下部分区域 -->
                <div class="basicInfoBottom">
                  <el-form-item label="适用渠道" prop="AllowOfflineShop">
                    <el-checkbox-group v-model="form.AllowOfflineShop">
                      <el-checkbox label="0">线下门店</el-checkbox>
                      <el-checkbox label="1">线上商城</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <el-form-item label="适用门店" prop="shopRangeType">
                    <el-radio-group v-model="form.shopRangeType">
                      <el-radio
                        :label="dict.value"
                        v-for="dict in dict.type.fill_shop_range_type"
                        :key="dict.value"
                        :value="dict.value"
                        >{{ dict.label }}</el-radio
                      >
                    </el-radio-group>
                  </el-form-item>
                  <!-- 门店表格区域 -->
                  <div
                    class="shopTable"
                    v-if="form.shopRangeType === '1' || form.shopRangeType === '2'"
                  >
                    <div class="btn x-f marB10">
                      <!-- <el-button size="mini" @click="openSelectShop"
                        >选择门店</el-button
                      > -->
                      <el-button
                        size="mini"
                        @click="
                          handleEvent('openShopDialog', { curTable: 'shopOptions' })
                        "
                        >选择门店</el-button
                      >
                    </div>
                    <!-- 门店表格 -->
                    <EditTable :options="shopOptions" />
                    <!-- form.shopItem -->
                  </div>
                  <!-- 代金券/折扣券 -->
                  <el-form-item
                    label="适用商品"
                    v-if="form.couponCaseType === '0' || form.couponCaseType === '1'"
                    prop="goodsRangeType"
                  >
                    <el-radio-group v-model="form.goodsRangeType">
                      <el-radio
                        :label="dict.value"
                        v-for="dict in dict.type.vip_goods_range_type"
                        :key="dict.value"
                        :value="dict.value"
                        >{{ dict.label }}</el-radio
                      >
                    </el-radio-group>
                  </el-form-item>
                  <!-- 抵扣券 -->
                  <el-form-item
                    label="抵扣商品"
                    v-if="form.couponCaseType === '2'"
                    prop="deductionGoodsType"
                  >
                    <div class="x-f">
                      <el-radio-group
                        @input="labelChange"
                        v-model="form.deductionGoodsType"
                      >
                        <el-radio
                          :label="dict.value"
                          v-for="dict in dict.type.deduction_goods_type"
                          :key="dict.value"
                          :value="dict.value"
                          >{{ dict.label }}</el-radio
                        >
                      </el-radio-group>
                      <el-input
                        disabled
                        size="mini"
                        v-if="form.deductionGoodsType === '0'"
                        class="deductionGoodsTypeDis"
                      ></el-input>
                      <el-form-item
                        prop="deductionGoodsPiece"
                        v-if="form.deductionGoodsType === '1'"
                        class="deductionGoodsType"
                      >
                        <el-input
                          size="mini"
                          v-model="form.deductionGoodsPiece"
                        ></el-input>
                      </el-form-item>
                      <div>件</div>
                    </div>
                  </el-form-item>
                  <div
                    class="goodsTable"
                    v-if="
                      form.goodsRangeType === '1' ||
                      form.goodsRangeType === '2' ||
                      (form.deductionGoodsType === '0' && form.couponCaseType === '2') ||
                      (form.deductionGoodsType === '1' && form.couponCaseType === '2')
                    "
                  >
                    <div class="btn x-f marB10">
                      <el-button
                        size="mini"
                        @click="
                          handleEvent('openGoodsDialog', { curTable: 'goodsOptions' })
                        "
                        >选择商品</el-button
                      >
                      <el-button
                        size="mini"
                        @click="
                          handleEvent('openCategoryDialog', { curTable: 'goodsOptions' })
                        "
                        v-show="form.couponCaseType !== '2'"
                        >选择类别</el-button
                      >
                      <el-button
                        size="mini"
                        @click="
                          handleEvent('openBrandDialog', { curTable: 'goodsOptions' })
                        "
                        v-show="form.couponCaseType !== '2'"
                        >选择品牌</el-button
                      >
                    </div>
                    <!-- 商品表格 -->
                    <EditTable :options="goodsOptions" />
                    <!-- form.goodsItem -->
                  </div>
                  <div class="x-x">
                    <el-form-item label="发放对象" prop="AllowVip">
                      <el-checkbox-group v-model="form.AllowVip" @change="handleCheckVip">
                        <el-checkbox label="0">全部会员</el-checkbox>
                        <el-checkbox label="1">全部会员+排除</el-checkbox>
                        <el-checkbox label="2">指定会员</el-checkbox>
                        <!-- <el-checkbox label="3">非会员</el-checkbox> -->
                        <el-checkbox label="4">生日会员</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                    <el-select
                      v-model="form.vipBirthdayType"
                      size="mini"
                      class="selectVipBD"
                      v-if="showVipBD"
                    >
                      <el-option
                        :label="dict.label"
                        v-for="dict in dict.type.vip_birthday_type"
                        :key="dict.value"
                        :value="dict.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="vipTable" v-if="vipTable">
                    <div class="btn x-f marB10">
                      <el-button
                        size="mini"
                        @click="handleEvent('openVipDialog', { curTable: 'vipOptions' })"
                        >选择会员</el-button
                      >
                      <el-button
                        size="mini"
                        @click="
                          handleEvent('openVipLevelDialog', { curTable: 'vipOptions' })
                        "
                        >选择会员级别</el-button
                      >
                    </div>
                    <!-- 会员表格 -->
                    <EditTable :options="vipOptions" />
                    <!-- form.vipLevelItem -->
                  </div>
                  <el-form-item label="适用时间" prop="useTimeRangeType">
                    <el-radio-group v-model="form.useTimeRangeType">
                      <el-radio
                        :label="dict.value"
                        v-for="dict in dict.type.use_time_range_type"
                        :key="dict.value"
                        :value="dict.value"
                        >{{ dict.label }}</el-radio
                      >
                    </el-radio-group>
                  </el-form-item>
                  <div class="timeTable" v-if="form.useTimeRangeType === '2'">
                    <div class="btn x-f marB10">
                      <el-button
                        size="mini"
                        @click="
                          handleEvent('openTimeDateDialog', {
                            curTable: 'timeDateOptions',
                          })
                        "
                        >选择时间</el-button
                      >
                    </div>
                    <!-- 时间表格 -->
                    <EditTable :options="timeDateOptions" />
                  </div>
                  <el-form-item label="销售设置" prop="isEnableSale">
                    <div class="x-x">
                      <el-checkbox
                        v-model="form.isEnableSale"
                        :disabled="form.couponMode == '1'"
                        >支持销售</el-checkbox
                      >
                      <div class="x-x marL15">
                        售价
                        <el-input
                          class="inputW100"
                          disabled
                          size="mini"
                          v-if="!form.isEnableSale"
                        ></el-input>
                        <el-form-item label-width="0" prop="salePrice" v-else>
                          <el-input
                            size="mini"
                            class="inputW100"
                            v-model="form.salePrice"
                          ></el-input>
                        </el-form-item>
                        元
                      </div>
                    </div>
                  </el-form-item>
                </div>
              </div>
            </template>
          </cardTitleCom>
          <cardTitleCom cardTitle="领取限制">
            <template slot="cardContent">
              <!-- 领取限制区域 -->
              <div class="getRestrict">
                <el-form-item label="优惠券总量" prop="couponCount">
                  <el-input
                    size="mini"
                    placeholder="请输入"
                    v-model="form.couponCount"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="每人限领次数"
                  prop="limitType"
                  v-if="form.couponCaseType != '3'"
                >
                  <el-radio label="0" v-model="form.limitType">不限次数</el-radio>
                </el-form-item>
                <div class="x-x" v-if="form.couponCaseType != '3'">
                  <el-form-item class="frequency" prop="limitType">
                    <el-radio label="1" v-model="form.limitType">{{ "" }}</el-radio>
                  </el-form-item>
                  <el-input
                    disabled
                    size="mini"
                    v-if="form.limitType === '0'"
                    class="limitCountDis"
                  ></el-input>
                  <el-form-item
                    prop="limitCount"
                    v-if="form.limitType === '1'"
                    class="num"
                  >
                    <el-input size="mini" v-model="form.limitCount"></el-input>
                  </el-form-item>
                  <div class="countText">次</div>
                </div>
                <el-form-item label="转赠设置" v-if="form.couponCaseType != '3'">
                  <el-checkbox label="允许转赠" v-model="form.isCanGive"
                    >允许转赠</el-checkbox
                  >
                </el-form-item>
              </div>
            </template>
          </cardTitleCom>
          <cardTitleCom cardTitle="其他限制" v-if="form.couponCaseType != '3'">
            <template slot="cardContent">
              <!-- 其他限制区域 -->
              <div class="otherRestrictions">
                <!-- 代金券 -->
                <div v-if="form.couponCaseType == '0'">
                  <el-form-item label="叠加设置">
                    <el-checkbox
                      label="同方案叠加"
                      v-model="form.isAllowSameCouponCaseReagio"
                    ></el-checkbox>
                    <el-checkbox
                      label="同类型叠加"
                      v-model="form.isAllowSameCouponTypeReagio"
                    ></el-checkbox>
                    <el-checkbox
                      label="折扣券叠加"
                      v-model="form.isAllowAgioCouponReagio"
                    ></el-checkbox>
                  </el-form-item>
                  <div
                    v-if="
                      form.isAllowSameCouponCaseReagio || form.isAllowSameCouponTypeReagio
                    "
                  >
                    <el-form-item>
                      <el-checkbox v-model="form.isLimitSameCouponReagio"
                        >叠加限制</el-checkbox
                      >
                      <el-radio
                        v-model="form.limitSameCouponReagioType"
                        :label="1"
                        class="limitQty"
                        >数量限制</el-radio
                      >
                      <el-input
                        disabled
                        size="mini"
                        v-if="form.limitSameCouponReagioType != 1"
                        class="inputW100"
                      ></el-input>
                      <el-input
                        size="mini"
                        v-model="form.limitSameCouponReagioCount"
                        class="inputW100"
                        v-if="form.limitSameCouponReagioType === 1"
                      ></el-input>
                      <!-- <el-radio
                        v-model="form.limitSameCouponReagioType"
                        :label="2"
                        >阶梯限制</el-radio
                      > -->
                    </el-form-item>
                  </div>

                  <el-form-item v-if="form.limitSameCouponReagioType == 2">
                    <!-- 阶梯表格 -->
                    <!-- <el-table
                      class="marT10"
                      :data="form.limitSameCouponReagio"
                      border
                      @cell-mouse-enter="cellMouseEnter"
                      @cell-mouse-leave="cellMouseLeave"
                    >
                      <el-table-column align="center" width="80">
                        <template v-slot="scope">
                          <i
                            v-if="scope.row.hoverRow"
                            @click="handleTableRow('push', scope.$index)"
                            class="el-icon-circle-plus operatePush"
                          ></i>
                          <i
                            v-if="scope.row.hoverRow"
                            @click="handleTableRow('del', scope.$index)"
                            class="el-icon-remove operateDel"
                          ></i>
                          <div v-if="!scope.row.hoverRow">
                            {{ scope.$index + 1 }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="金额"
                        width="200"
                        align="center"
                        prop="money"
                      >
                        <template slot="header">
                          <i style="color: #ff4949">*</i>
                          金额
                        </template>
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="
                              `limitSameCouponReagio[${scope.$index}].money`
                            "
                            :rules="rules[`limitSameCouponReagio.money`]"
                          >
                            <el-input v-model="scope.row.money"> </el-input>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="限制使用优惠券数量"
                        width="200"
                        align="center"
                        prop="limitCouponQty"
                      >
                        <template slot="header">
                          <i style="color: #ff4949">*</i>
                          限制使用优惠券数量
                        </template>
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="
                              `limitSameCouponReagio[${scope.$index}].limitCouponQty`
                            "
                            :rules="
                              rules[`limitSameCouponReagio.limitCouponQty`]
                            "
                          >
                            <el-input v-model="scope.row.limitCouponQty">
                            </el-input>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column> </el-table-column>
                    </el-table> -->
                  </el-form-item>
                </div>
                <!-- 折扣券 -->
                <div v-if="form.couponCaseType == '1'">
                  <el-form-item label="叠加设置">
                    <el-checkbox
                      label="代金券叠加"
                      v-model="form.isAllowCashCouponReagio"
                    ></el-checkbox>
                    <el-radio :label="0" v-model="form.cashCouponReagioType"
                      >先折扣再减现</el-radio
                    >
                    <el-radio :label="1" v-model="form.cashCouponReagioType"
                      >先减现再折扣</el-radio
                    >
                  </el-form-item>
                </div>
                <!-- 抵扣券 -->
                <div v-if="form.couponCaseType == '2'">
                  <el-form-item label="叠加设置">
                    <!-- <el-radio
                      v-model="form.limitSameCouponReagioType"
                      :label="1"
                      >数量限制</el-radio
                    >
                    <el-input
                      v-model="form.limitSameCouponReagioCount"
                      class="inputW100"
                    ></el-input> -->
                    <div class="tag">
                      本方案的抵扣券默认可跟其他方案的抵扣券、代金券、折扣券叠加使用，先抵扣后再根据折扣券的减折规则计算收款金额
                    </div>
                  </el-form-item>
                </div>
                <el-form-item label="其他限制">
                  <div class="x-f" style="height: 36px">
                    <el-radio v-model="form.limitShareType" :label="0"
                      >不可与其它优惠共享</el-radio
                    >
                    <el-radio v-model="form.limitShareType" :label="1"
                      >可与其它优惠共享</el-radio
                    >
                    <el-checkbox
                      label="改价"
                      v-model="form.isLimitShareChangePrice"
                      :disabled="form.limitShareType != 1"
                    ></el-checkbox>
                    <el-checkbox
                      label="促销"
                      v-model="form.isLimitSharePromoCase"
                      :disabled="form.limitShareType != 1"
                    ></el-checkbox>
                  </div>
                </el-form-item>
                <!-- <el-form-item v-if="form.limitShareType == 1">
                    <el-checkbox label="促销" v-model="form.isLimitShareUnableUse">不能使用,当单品折扣低于</el-checkbox>
                    <el-input
                    disabled
                    v-if="!form.isLimitShareUnableUse"
                    class="inputW100"
                     ></el-input>
                    <el-input v-model="form.limitShareUnableUseAgio" class="inputW100" v-if="form.isLimitShareUnableUse"></el-input>
                    折(%)
                </el-form-item> -->
                <el-form-item label="优惠券支付设置">
                  <el-checkbox
                    label="允许会员储值支付"
                    v-model="form.isAllowVipPay"
                  ></el-checkbox>
                  <!-- <el-checkbox
                    label="允许礼品卡支付"
                    v-model="form.isAllowGiftCardPay"
                  ></el-checkbox> -->
                </el-form-item>
              </div>
            </template>
          </cardTitleCom>
        </el-form>

        <!-- 弹窗组件 -->
        <Dialog
          ref="tablePage"
          :options.sync="dialogOptions"
          @handleEvent="handleEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import Dialog from "@/components/Dialog";
import { getDateChangeDetail } from "@/utils/public"; //适用时间转换
import EditTable from "@/components/editTable"; //可编辑表格
import { uniqWith } from "lodash"; //去重
import comTable from "@/views/components/com-table"; //表格
// import { getNewBillNo } from '@/utils/numFormatting' //获取编号
import {
  getBillNo,
  saveCoupon, //新增
  couponDetail, //详情
  updateCoupon, //编辑
} from "@/api/marketing/coupon/case";
export default {
  name: "DiscountSchemeDetail",
  dicts: [
    "fill_shop_range_type", //门店范围
    "vip_goods_range_type", //商品范围
    "use_time_range_type", //时间范围
    "vip_applicable_commodities_appoint_type", //商品表格中类型
    "member_type", //会员表格中类型
    "vip_birthday_type", //会员生日范围
    "coupon_mode", //优惠券形式
    "deduction_goods_type", //抵扣商品类型
  ],
  components: {
    cardTitleCom,
    topOperatingButton,
    comTable,
    Dialog,
    EditTable,
  },
  props: {
    //优惠券方案信息  根据信息渲染优惠券方案类型
    schemeInfo: {
      type: Object,
    },
  },
  data() {
    return {
      curTable: "",
      isDisabled: false,
      timeDateOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        echoList: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "dateTime",
            label: "时间范围",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "",
            label: "",
            minWidth: 200,
            align: "center",
          },
        ],
      },
      shopOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: 200, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "shopNo",
            label: "编号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "shopName",
            label: "名称",
            minWidth: 120,
            align: "center",
          },
        ],
      },
      goodsOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: 200, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "lineType",
            label: "类型",
            minWidth: 120,
            align: "center",
            formatter: (val) => {
              return val == 1 ? "商品" : val == 2 ? "品牌" : "类别";
            },
          },
          {
            prop: "lineNo",
            label: "编号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "lineName",
            label: "名称",
            minWidth: 120,
            align: "center",
          },

          {
            prop: "unitName",
            label: "单位",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "goodsSpec",
            label: "规格",
            minWidth: 120,
            align: "center",
          },
          //{
          //   type: 'my-select-local',
          //   prop: 'unitId',
          //   label: '单位',
          //   minWidth: 120,
          //   align: 'center',
          //   option: (row)=>{
          //     console.log(row, 'row')
          //     return {
          //       data:row?.units?.map?.(item => ({
          //           ...item,
          //           label: item.unitName,
          //           value: item.unitId,
          //       })) || [],
          //       label: 'unitName',
          //       value: 'unitId',
          //       filterable: true
          //     }
          //   }
          // },
          // {
          //   prop: 'limitQty',
          //   label: '数量',
          //   minWidth: 120,
          //   align: 'center',
          //   type: 'input',
          //   input: 'sumBalance',
          //   disabled: false,
          // },
        ],
      },
      vipOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: 200, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "lineType",
            label: "类型",
            minWidth: 120,
            align: "center",
            formatter: (val) => {
              return val == 1 ? "会员" : "会员级别";
            },
          },
          {
            prop: "lineNo",
            label: "编号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "lineName",
            label: "名称",
            minWidth: 120,
            align: "center",
          },
        ],
      },
      dialogOptions: {
        curType: "", //当前类型(门店/会员/商品/类别/品牌)
        title: "选择商品",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      disCouponMode: false, //禁用优惠券类型
      checkedCities: [],
      //整体页面加载动画
      boxLoading: false,
      //优惠券方案标题
      title: "",
      //日期时间id(方便删除识别)
      timeId: undefined,
      //会员表格开关
      vipTable: false,
      //选择门店开关
      OpenShop: false,
      //选择会员级别开关
      OpenVipRank: false,
      //选择会员开关
      OpenVipCards: false,
      //选择商品开关
      OpenGoods: false,
      //选择类别开关
      OpenCategory: false,
      //选择品牌开关
      OpenBrand: false,
      //选择时间日期开关
      OpenDate: false,
      //开启会员生日下拉框开关
      showVipBD: false,
      //门店表格字段
      shopTableLable: [
        { label: "编号", prop: "shopNo" },
        { label: "名称", prop: "shopName" },
      ],
      //商品表格字段
      goodsTableLable: [
        { label: "编号", prop: "lineNo" },
        { label: "名称", prop: "lineName" },
        { label: "规格", prop: "goodsSpec" },
        { label: "单位", prop: "unitName" },
      ],
      //会员表格字段
      vipTableLable: [
        { label: "编号", prop: "lineNo" },
        { label: "名称", prop: "lineName" },
      ],
      //时间范围表格字段
      timeTableLable: [{ label: "时间范围", prop: "dateTime" }],
      //日期时间累加存放
      specialDate: [],
      //有效时间/方案时间 禁用当天之前的时间
      forbiddenTime: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      //表单
      form: {
        couponCaseId: undefined, //优惠券方案id
        couponCaseType: undefined, //优惠券类型(0代金券1折扣券2抵扣券)
        couponCaseNo: undefined, //优惠券方案编号
        couponCaseName: undefined, //优惠券方案名称
        colorType: "4", //优惠券颜色
        couponMode: undefined, //优化券形式
        parValue: undefined, //面值
        sillType: "0", //门槛
        sillMoney: undefined, //门槛金额
        couponNoCreateMode: 0, //券码生成
        //有效时间
        validTime: undefined,
        validBegTime: undefined, //有效开始时间
        validEndTime: undefined, //有效结束时间
        isSpecalTime: false, //是否特殊领用时间
        drawDay: undefined, //(特殊领用)领取n天后生效
        validDay: undefined, //(特殊领用)有效期n天
        couponDesc: undefined, //优惠券说明
        //适用渠道
        AllowOfflineShop: [],
        isAllowOfflineShop: false, //适用渠道(线下门店)
        isAllowOnlineShop: false, //适用渠道(线上商城)
        shopRangeType: "0", //适用门店
        shopItem: [], //适用门店表格数据
        goodsRangeType: "0", //适用商品
        deductionGoodsType: "0", //抵扣商品
        deductionGoodsPiece: undefined, //抵扣商品件数
        goodsItem: [], //适用商品表格数据
        //适用对象
        AllowVip: ["0"],
        isAllVip: false, //是否全部会员
        isAllVipExd: false, //是否全部会员+排除
        isSpecalVip: false, //是否指定会员
        isNotVip: false, //是否非会员
        isBirthdayVip: false, //是否生日会员
        vipBirthdayType: "1", //生日会员范围
        vipLevelItem: [], //适用会员表格数据
        useTimeRangeType: "0", //适用时间
        specialDateItem: [], //适用时间数据
        isEnableSale: false, //是否销售
        salePrice: undefined, //销售售价
        couponCount: undefined, //优惠券总数
        limitType: "0", //限领次数类型
        limitCount: undefined, //限领次数
        isCanGive: undefined, //是否允许转增
        //其他限制
        isAllowSameCouponCaseReagio: false, //同方案叠加
        isAllowSameCouponTypeReagio: false, //同类型叠加
        isAllowAgioCouponReagio: false, //折扣券叠加
        isAllowCashCouponReagio: false, //代金券叠加
        isLimitSameCouponReagio: true, //叠加限制
        limitSameCouponReagioType: 1, //叠加限制类型（1 数量限制 2 阶梯限制）
        limitSameCouponReagioCount: 1, //数量限制的数量
        limitShareType: 0, //限制类型（0 不可与其它优惠共享 1 可与其它优惠共享）
        isLimitShareChangePrice: false, //可与其它优惠共享时，是否支持改价
        isLimitSharePromoCase: false, //可与其它优惠共享时，是否支持促销
        isLimitShareUnableUse: false, //可与其它优惠共享时，是否支持不能使用，当单品折扣低于折扣
        limitShareUnableUseAgio: undefined, //不能使用，当单品折扣低于折扣(%)
        isAllowVipPay: true, //允许会员储蓄支付
        isAllowGiftCardPay: true, //允许礼品卡支付
        limitSameCouponReagio: [{}], //阶梯限制数组
      },
      //表单校验
      rules: {
        couponCaseNo: [
          {
            required: true,
            message: "请输入优惠券方案编号",
            trigger: ["blur", "change"],
          },
        ],
        couponCaseName: [
          {
            required: true,
            message: "请输入优惠券方案名称",
            trigger: ["blur", "change"],
          },
        ],
        couponMode: [
          {
            required: true,
            message: "请选择优惠券形式",
            trigger: "",
          },
        ],
        parValue: [{ required: true, message: "请输入", trigger: ["blur", "change"] }],
        sillType: [
          { required: true, message: "请选择门槛", trigger: ["blur", "change"] },
        ],
        validTime: [
          {
            required: true,
            message: "请选择有效时间",
            trigger: ["blur", "change"],
          },
        ],
        emitTime: [
          {
            required: true,
            message: "请选择方案时间",
            trigger: ["blur", "change"],
          },
        ],
        shopRangeType: [
          {
            required: true,
            message: "请选择适用门店",
            trigger: ["blur", "change"],
          },
        ],
        goodsRangeType: [
          {
            required: true,
            message: "请选择适用商品",
            trigger: ["blur", "change"],
          },
        ],
        deductionGoodsType: [
          { required: true, message: "请选择抵扣", trigger: ["blur", "change"] },
        ],
        deductionGoodsPiece: [
          {
            required: true,
            message: "请输入抵扣件数",
            trigger: ["blur", "change"],
          },
        ],
        couponCount: [
          {
            required: true,
            message: "请输入优惠券总数",
            trigger: ["blur", "change"],
          },
        ],
        limitType: [
          {
            required: true,
            message: "请输入优惠券限领",
            trigger: ["blur", "change"],
          },
        ],
        useTimeRangeType: [
          {
            required: true,
            message: "请选择适用时间",
            trigger: ["blur", "change"],
          },
        ],
        AllowOfflineShop: [
          {
            required: true,
            message: "请选择适用渠道",
            trigger: ["blur", "change"],
          },
        ],
        AllowVip: [
          {
            required: true,
            message: "请选择适用对象",
            trigger: ["blur", "change"],
          },
        ],
        limitCount: [
          { required: true, message: "请输入次数", trigger: ["blur", "change"] },
        ],
        sillMoney: [
          { required: true, message: "请输入金额", trigger: ["blur", "change"] },
        ],
        salePrice: [
          { required: true, message: "请输入售价", trigger: ["blur", "change"] },
        ],
        //阶梯限制
        "limitSameCouponReagio.money": [
          { required: true, message: "请输入金额", trigger: ["blur", "change"] },
        ],
        "limitSameCouponReagio.limitCouponQty": [
          {
            required: true,
            message: "请输入限制使用优惠券数量",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  watch: {
    //侦听方案类型信息   判断是新增功能或修改功能
    schemeInfo: {
      handler(info) {
        if (info.SchemeId == 2) {
          if (!this.goodsOptions.columns.some((item) => item.prop === "limitQty")) {
            this.goodsOptions.columns.push({
              prop: "limitQty",
              label: "数量",
              minWidth: 120,
              align: "center",
              type: "input",
              input: "sumBalance",
              disabled: false,
            });
          }
        }
        if (info.type === "add") {
          //新增
          this.reset();
          this.form.couponCaseType = info.SchemeId; //优惠券方案类型id

          this.title = info.title; //优惠券方案名称
          this.getNewBillNo();
        } else {
          //页面加载动画
          this.boxLoading = true;
          //修改
          this.title = info.title; //优惠券方案名称
          const couponCaseId = info.couponCaseId; //优惠券id
          this.getCouponDetail(couponCaseId); //根据id获取优惠券详情
        }
        if (this.form.couponCaseType == "2") {
          this.goodsTableLable.push({
            label: "最多可选数量",
            prop: "limitQty",
            type: "input",
          });
        }
      },
    },
    //侦听发放对象 当全部会员+排除/指定会员时 显示会员表格 反之隐藏
    "form.AllowVip": {
      handler(val) {
        if (val) {
          //当选中全部会员+排除/指定会员时 显示会员表格 反之隐藏
          const bool = val.some((v) => v === "1" || v === "2");
          this.vipTable = bool ? true : false;
          //当选中生日会员  显示会员生日下拉框 反之隐藏
          const vipBD = val.some((v) => v === "4");
          this.showVipBD = vipBD ? true : false;
        }
      },
      immediate: true,
    },
    //侦听有效时间
    "form.validTime": {
      handler(val) {
        //有效时间转换赋值
        if (val) {
          this.form.validBegTime = val[0];
          this.form.validEndTime = val[1];
        }
      },
      deep: true,
      immediate: true,
    },
    //侦听方案时间
    "form.emitTime": {
      handler(val) {
        //有效时间转换赋值
        if (val && val.length > 0) {
          this.form.emitBegTime = val[0];
          this.form.emitEndTime = val[1];
        }
      },
      deep: true,
      immediate: true,
    },
    //侦听其他限制 可与其他优惠共享 默认打钩改价/促销
    "form.limitShareType": {
      handler(val) {
        if (val == 1) {
          this.form.isLimitShareChangePrice = true;
          this.form.isLimitSharePromoCase = true;
        } else {
          this.form.isLimitShareChangePrice = false;
          this.form.isLimitSharePromoCase = false;
        }
      },
    },
    "form.couponMode": {
      handler(val) {
        if (val == "1") {
          //纸质优惠券
          this.form.isEnableSale = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.timeId = Date.now(); //获取日期时间识别id
  },
  methods: {
    labelChange(e) {
      console.log(e, "ee");
      if (e == 0) {
        this.goodsOptions.columns = [
          {
            prop: "lineType",
            label: "类型",
            minWidth: 120,
            align: "center",
            formatter: (val) => {
              return val == 1 ? "商品" : val == 2 ? "品牌" : "类别";
            },
          },
          {
            prop: "lineNo",
            label: "编号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "lineName",
            label: "名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "unitName",
            label: "单位",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "goodsSpec",
            label: "规格",
            minWidth: 120,
            align: "center",
          },
          // {
          //   type: 'my-select-local',
          //   prop: 'unitId',
          //   label: '单位',
          //   minWidth: 120,
          //   align: 'center',
          //   option: (row)=>{
          //     console.log(row, 'row')
          //     return {
          //       data:row?.units?.map?.(item => ({
          //           ...item,
          //           label: item.unitName,
          //           value: item.unitId,
          //       })) || [],
          //       label: 'unitName',
          //       value: 'unitId',
          //       filterable: true
          //     }
          //   }
          // },
          {
            prop: "limitQty",
            label: "数量",
            minWidth: 120,
            align: "center",
            type: "input",
            disabled: false,
          },
        ];
      } else {
        this.goodsOptions.columns = [
          {
            prop: "lineType",
            label: "类型",
            minWidth: 120,
            align: "center",
            formatter: (val) => {
              return val == 1 ? "商品" : val == 2 ? "品牌" : "类别";
            },
          },
          {
            prop: "lineNo",
            label: "编号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "lineName",
            label: "名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "unitName",
            label: "单位",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "goodsSpec",
            label: "规格",
            minWidth: 120,
            align: "center",
          },
          // {
          //   type: 'my-select-local',
          //   prop: 'unitId',
          //   label: '单位',
          //   minWidth: 120,
          //   align: 'center',
          //   option: (row)=>{
          //     return {
          //       data:row?.units?.map?.(item => ({
          //           ...item,
          //           label: item.unitName,
          //           value: item.unitId,
          //       })) || [],
          //       label: 'unitName',
          //       value: 'unitId',
          //       filterable: true
          //     }
          //   }
          // },
        ];
      }
    },
    //处理表格数据(商品/类别/品牌)
    getTableData(type, list) {
      let newList = [];
      switch (type) {
        case "goodsId":
          newList = list.flatMap((item) => ({
            ...item,
            lineType: 1,
            lineId: item.goodsId,
            lineName: item.goodsName,
            lineNo: item.goodsNo,
            limitQty: "",
          }));
          break;
        case "categoryId":
          newList = list.map((item) => ({
            ...item,
            lineType: 3,
            lineId: item.categoryId,
            lineName: item.categoryName,
            lineNo: item.categoryNo,
          }));
          break;
        case "brandId":
          newList = list.map((item) => ({
            ...item,
            lineType: 2,
            lineId: item.brandId,
            lineName: item.brandName,
            lineNo: item.brandNo,
          }));
          break;
        case "vipLevelId":
          newList = list.flatMap((item) => ({
            ...item,
            lineType: 2,
            lineId: item.vipLevelId,
            lineName: item.vipLevelName,
            lineNo: item.vipLevelNo,
          }));
          break;
        case "vipId":
          newList = list.flatMap((item) => ({
            ...item,
            lineType: 1,
            lineId: item.vipId,
            lineName: item.vipName,
            lineNo: item.vipNo,
          }));
          break;
        default:
          break;
      }
      return newList;
    },
    async handleEvent(type, row) {
      if (row?.curTable) this.curTable = row.curTable; //标识当前表格
      console.log(this.curTable, "this.curTable");
      switch (type) {
        case "openShopDialog":
          this.dialogOptions = {
            curType: "shopId",
            title: "选择门店",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "shop" }),
          };
          break;
        case "openGoodsDialog":
          this.dialogOptions = {
            curType: "goodsId",
            title: "选择商品",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "goods" }),
          };
          break;
        case "openCategoryDialog":
          this.dialogOptions = {
            curType: "categoryId",
            title: "选择类别",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "category" }),
          };
          break;
        case "openBrandDialog":
          this.dialogOptions = {
            curType: "brandId",
            title: "选择品牌",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "brand" }),
          };
          break;
        case "openVipDialog":
          this.dialogOptions = {
            curType: "vipId",
            title: "选择会员",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "vip" }),
          };
          break;
        case "openVipLevelDialog":
          this.dialogOptions = {
            curType: "vipLevelId",
            title: "选择会员级别",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "vipLevel" }),
          };
          break;
        case "openTimeDateDialog":
          //弹窗配置
          this.dialogOptions = {
            curType: "timeDate",
            title: "选择时间日期",
            width: 600,
            show: true,
            type: "TimeDateSelect",
            formData: {
              DateTimeList: this.timeDateOptions.echoList, // 日期
            },
          };
          break;
        case "dialogChange":
          let list = [];
          let dialogType = this.dialogOptions.curType;
          if (dialogType != "timeDate") {
            console.log(dialogType, "dialogType");
            console.log(
              this.dialogOptions.formData.table.check,
              "this.dialogOptions.formData.table.check"
            );
            if (this.dialogOptions.formData.table.check.length <= 0) return;
            list = this.dialogOptions.formData.table.check;
            if (["shopId"].includes(dialogType)) {
              list = [...list, ...this.shopOptions.list];
              list = uniqWith(list, (x, y) => x[dialogType] == y[dialogType]);
              this.shopOptions.list = list;
            } else if (["goodsId", "categoryId", "brandId"].includes(dialogType)) {
              list = this.getTableData(dialogType, list);
              // list = [...list, ...this.goodsOptions.list]
              // list = uniqWith(list, (x, y) => x.lineId == y.lineId)
              if (dialogType == "goodsId") {
                list = list.flatMap((item) =>
                  item.units.map((i) => ({
                    ...item,
                    ...i,
                  }))
                );
                list = [...list, ...this.goodsOptions.list];
              } else {
                list = [...list, ...this.goodsOptions.list];
                list = uniqWith(list, (x, y) => x.lineId == y.lineId);
              }
              // list = list.filter(e => e.goodsId)
              console.log(list, "list");
              this.goodsOptions.list = list;
            } else if (["vipId", "vipLevelId"].includes(dialogType)) {
              list = this.getTableData(dialogType, list);
              list = [...list, ...this.vipOptions.list];
              list = uniqWith(list, (x, y) => x.lineId == y.lineId);
              this.vipOptions.list = list;
            }
          } else {
            console.log(
              this.dialogOptions.formData.DateTimeList,
              "this.dialogOptions.formData.DateTimeList"
            );
            this.timeDateOptions.echoList = this.dialogOptions.formData.DateTimeList;
            list = await getDateChangeDetail(this.dialogOptions.formData.DateTimeList);
            this.timeDateOptions.list = list;
          }
          break;
        default:
          break;
      }
    },
    getQuit() {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          (item) => item.path === this.$route.path
        ),
        1
      );
      history.back();
    },
    //设置优惠价背景颜色
    setCardBg(color) {
      this.form.colorType = color;
    },
    // 打开选择门店对话框
    openSelectShop() {
      this.OpenShop = true;
    },
    //获取编号事件
    async getNewBillNo() {
      let res = await getBillNo();
      this.$set(this.form, "couponCaseNo", res.data || "");
    },
    //选中发放对象改变触发(选中全部会员去除指定会员,选中指定会员去除全部会员)
    handleCheckVip() {
      if (this.form.AllowVip.length > 1) {
        // 拿到最后一个选择的数据  要以最后一个选择的为判断
        if (this.form.AllowVip[this.form.AllowVip.length - 1] === "2") {
          //当最后一个数据是指定会员 去除全部会员/全部会员+排除
          this.form.AllowVip = this.form.AllowVip.filter((v) => v !== "0" && v !== "1");
        } else if (this.form.AllowVip[this.form.AllowVip.length - 1] === "0") {
          //当最后一个数据是全部会员 去除指定会员/全部会员+排除/会员生日
          this.form.AllowVip = this.form.AllowVip.filter(
            (v) => v !== "2" && v !== "4" && v !== "1"
          );
        } else if (this.form.AllowVip[this.form.AllowVip.length - 1] === "1") {
          //当最后一个数据是全部会员+排除 去除指定会员/全部会员/会员生日
          this.form.AllowVip = this.form.AllowVip.filter(
            (v) => v !== "2" && v !== "4" && v !== "0"
          );
        } else if (this.form.AllowVip[this.form.AllowVip.length - 1] === "4") {
          //当最后一个数据是会员生日 去除全部会员/全部会员+排除
          this.form.AllowVip = this.form.AllowVip.filter((v) => v !== "0" && v !== "1");
        }
      }
    },

    //删除表格行事件(共同删除事件,以type区分表格(门店/商品/会员/时间))
    delTableRow(row, type) {
      if (type === "shop") {
        //门店
        //过滤掉删除的数据行
        this.form.shopItem = this.form.shopItem.filter((v) => v.shopId !== row.shopId);
      } else if (type === "goods") {
        //商品
        //过滤掉删除的数据行
        this.form.goodsItem = this.form.goodsItem.filter((v) => v.lineId !== row.lineId);
      } else if (type === "vip") {
        //会员
        //过滤掉删除的数据行
        this.form.vipLevelItem = this.form.vipLevelItem.filter(
          (v) => v.lineId !== row.lineId
        );
      } else if (type === "time") {
        //时间
        //过滤掉删除的数据行
        this.form.specialDateItem = this.form.specialDateItem.filter(
          (v) => v.id !== row.id
        );
        this.specialDate = this.specialDate.filter((v) => v.id !== row.id); //累加存放的日期时间 也需要过滤掉删除的数据行
      }
    },
    //根据id获取优惠券详情
    getCouponDetail(couponCaseId) {
      //初始化数据
      this.reset();
      this.disCouponMode = true; //禁用优惠券形式
      couponDetail(couponCaseId).then(async (res) => {
        this.form = res.data;
        //适用渠道转换
        this.$set(this.form, "AllowOfflineShop", []);
        if (this.form.isAllowOfflineShop) this.form.AllowOfflineShop.push("0");
        if (this.form.isAllowOnlineShop) this.form.AllowOfflineShop.push("1");
        //发放对象转换
        this.$set(this.form, "AllowVip", []);
        if (this.form.isAllVip) this.form.AllowVip.push("0"); //是否全部会员
        if (this.form.isAllVipExd) this.form.AllowVip.push("1"); //是否全部会员+排除
        if (this.form.isSpecalVip) this.form.AllowVip.push("2"); //是否指定会员
        if (this.form.isNotVip) this.form.AllowVip.push("3"); //是否非会员
        if (this.form.isBirthdayVip) this.form.AllowVip.push("4"); //是否生日会员
        //有效时间转换
        this.$set(this.form, "validTime", []);
        this.form.validTime.push(this.form.validBegTime);
        this.form.validTime.push(this.form.validEndTime);
        //方案时间转换
        this.$set(this.form, "emitTime", []);
        if (this.form.emitBegTime) {
          this.form.emitTime.push(this.form.emitBegTime);
        }
        if (this.form.emitEndTime) {
          this.form.emitTime.push(this.form.emitEndTime);
        }
        //阶梯限制数组json转为数组
        if (this.form.limitSameCouponReagioStep) {
          this.$set(this.form, "limitSameCouponReagio", []);
          this.form.limitSameCouponReagio = JSON.parse(
            this.form.limitSameCouponReagioStep
          );
        }
        this.goodsOptions.list = this.form.goodsItem;
        this.vipOptions.list = this.form.vipLevelItem;
        this.shopOptions.list = this.form.shopItem;

        if (this.form.specialDateItem && this.form.specialDateItem.length > 0) {
          this.timeDateOptions.list = await getDateChangeDetail(
            this.form.specialDateItem
          );
        }
        this.boxLoading = false;
      });
    },
    //保存按钮
    submitForm(bool) {
      if (this.isDisabled) return;
      this.form.goodsItem = this.goodsOptions.list;
      if (this.goodsOptions.list) {
        let array = this.goodsOptions.list.reduce((acc, curr) => {
          const isDuplicate = acc.some(
            (item) => item.goodsSpec === curr.goodsSpec && item.lineNo == curr.lineNo
          );
          if (!isDuplicate) {
            acc.push(curr);
          }
          return acc;
        }, []);
        if (array.length != this.goodsOptions.list.length) {
          return this.$message.warning("商品规格重复");
        }
      }
      this.form.vipLevelItem = this.vipOptions.list;
      this.form.shopItem = this.shopOptions.list;
      if (
        this.form.goodsItem.length > 0 &&
        this.form.couponCaseType == 2 &&
        this.form.deductionGoodsType == 0
      ) {
        let findIndex = this.form.goodsItem.findIndex((item) => !item.limitQty);
        if (findIndex != -1) return this.$message.error("请输入正常的商品数量");
      }
      this.form.specialDateItem = this.timeDateOptions.list;
      // this.form.specialDateItem = this.timeDateOptions.echoList
      this.$refs["form"].validate((valid) => {
        //表单校验
        if (valid) {
          //校验全部门店+排除/指定门店 是否选择门店
          if (this.form.shopRangeType === "1" || this.form.shopRangeType === "2") {
            if (this.form.shopItem.length <= 0) return this.$message.error("请选择门店");
          }
          //校验全部商品+排除/指定商品  是否选择商品
          if (
            this.form.goodsRangeType === "1" ||
            this.form.goodsRangeType === "2" ||
            (this.form.deductionGoodsType === "0" && this.form.couponCaseType === "2") ||
            (this.form.deductionGoodsType === "1" && this.form.couponCaseType === "2")
          ) {
            if (this.form.goodsItem.length <= 0) return this.$message.error("请选择商品");
            // const listIndex = this.form.goodsItem.findIndex(y => y.limitQty == '' || y.limitQty == null || y.limitQty == undefined)
            // if(this.form.couponCaseType == '1' && listIndex != -1) {
            //   return this.$message.error('请输入商品数量')
            // }
          }
          //校验全部会员+排除/指定会员    是否选择会员
          if (this.vipTable) {
            if (this.form.vipLevelItem.length <= 0)
              return this.$message.error("请选择会员");
          }
          //校验指定时间    是否选择时间
          if (this.form.useTimeRangeType === "2") {
            if (this.form.specialDateItem.length <= 0)
              return this.$message.error("请选择时间");
          }
          //适用渠道字段转换赋值
          // this.form.AllowOfflineShop.forEach(v => {
          //   if (v === '0') this.form.isAllowOfflineShop = true //线下门店
          //   if (v === '1') this.form.isAllowOnlineShop = true //线上商城
          // })
          this.form.isAllowOnlineShop = this.form.AllowOfflineShop.includes("1")
            ? true
            : false;
          this.form.isAllowOfflineShop = this.form.AllowOfflineShop.includes("0")
            ? true
            : false;
          //发放对象字段转换赋值
          this.form.AllowVip.forEach((i) => {
            if (i === "0") this.form.isAllVip = true; //是否全部会员
            if (i === "1") this.form.isAllVipExd = true; //是否全部会员+排除
            if (i === "2") this.form.isSpecalVip = true; //是否指定会员
            if (i === "3") this.form.isNotVip = true; //是否非会员
            if (i === "4") this.form.isBirthdayVip = true; //是否生日会员
          });
          //售价转number
          if (this.form.isEnableSale) {
            this.form.salePrice = Number(this.form.salePrice);
          } else this.form.salePrice = undefined;
          //阶梯限制数组转json
          this.form.limitSameCouponReagioStep = JSON.stringify(
            this.form.limitSameCouponReagio
          );
          this.isDisabled = true;
          if (this.form.couponCaseId != undefined) {
            //修改事件
            updateCoupon(this.form)
              .then((res) => {
                this.$message.success("修改优惠券成功");
                this.isDisabled = false;
              })
              .catch(() => {
                this.isDisabled = false;
              });
          } else {
            //新增事件
            saveCoupon(this.form)
              .then((res) => {
                this.$message.success("新增优惠券成功");
                this.form.couponCaseId = res.data.couponCaseId;
                this.isDisabled = false;
              })
              .catch(() => {
                this.isDisabled = false;
              });
          }
          //保存并退出
          if (bool) {
            this.$router.push("/marketing/coupon/Case");
          }
        } else {
          this.isDisabled = false;
        }
      });
    },
    //重置初始化数据
    reset() {
      (this.disCouponMode = false), //禁用优惠券类型
        //表单
        (this.form = {
          couponCaseId: undefined, //优惠券方案id
          couponCaseType: undefined, //优惠券类型
          couponCaseNo: undefined, //优惠券方案编号
          couponCaseName: undefined, //优惠券方案名称
          colorType: "4", //优惠券颜色
          couponMode: undefined, //优化券形式
          parValue: undefined, //面值
          sillType: "0", //门槛
          sillMoney: undefined, //门槛金额
          couponNoCreateMode: 0, //券码生成
          //有效时间
          validTime: undefined,
          validBegTime: undefined, //有效开始时间
          validEndTime: undefined, //有效结束时间
          //方案时间
          emitTime: undefined,
          emitBegTime: undefined, //发券开始时间
          emitEndTime: undefined, //发券结束时间
          isSpecalTime: false, //是否特殊领用时间
          drawDay: undefined, //(特殊领用)领取n天后生效
          validDay: undefined, //(特殊领用)有效期n天
          couponDesc: undefined, //优惠券说明
          //适用渠道
          AllowOfflineShop: ["0", "1"],
          isAllowOfflineShop: false, //适用渠道(线下门店)
          isAllowOnlineShop: false, //适用渠道(线上商城)
          shopRangeType: "0", //适用门店
          shopItem: [], //适用门店表格数据
          goodsRangeType: "0", //适用商品
          deductionGoodsType: "0", //抵扣商品
          deductionGoodsPiece: undefined, //抵扣商品件数
          goodsItem: [], //适用商品表格数据
          //适用对象
          AllowVip: ["0"],
          isAllVip: false, //是否全部会员
          isAllVipExd: false, //是否全部会员+排除
          isSpecalVip: false, //是否指定会员
          isNotVip: false, //是否非会员
          isBirthdayVip: false, //是否生日会员
          vipBirthdayType: "1", //生日会员范围
          vipLevelItem: [], //适用会员表格数据
          useTimeRangeType: "0", //适用时间
          specialDateItem: [], //适用时间数据
          isEnableSale: false, //是否销售
          salePrice: undefined, //销售售价
          couponCount: undefined, //优惠券总数
          limitType: "0", //限领次数类型
          limitCount: undefined, //限领次数
          isCanGive: undefined, //是否允许转增
          //其他限制
          isAllowSameCouponCaseReagio: false, //同方案叠加
          isAllowSameCouponTypeReagio: false, //同类型叠加
          isAllowAgioCouponReagio: false, //折扣券叠加
          isAllowCashCouponReagio: false, //代金券叠加
          isLimitSameCouponReagio: true, //叠加限制
          limitSameCouponReagioType: 1, //叠加限制类型（1 数量限制 2 阶梯限制）
          limitSameCouponReagioCount: 1, //数量限制的数量
          limitShareType: 0, //限制类型（0 不可与其它优惠共享 1 可与其它优惠共享）
          isLimitShareChangePrice: false, //可与其它优惠共享时，是否支持改价
          isLimitSharePromoCase: false, //可与其它优惠共享时，是否支持促销
          isLimitShareUnableUse: false, //可与其它优惠共享时，是否支持不能使用，当单品折扣低于折扣
          limitShareUnableUseAgio: undefined, //不能使用，当单品折扣低于折扣(%)
          isAllowVipPay: true, //允许会员储蓄支付
          isAllowGiftCardPay: true, //允许礼品卡支付
          limitSameCouponReagio: [{}], //阶梯限制数组
        });
      this.specialDate = [];
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      //获取当前行下标
      let eleIndex = this.form.limitSameCouponReagio.indexOf(row);
      // 注意必须是使用两次深拷贝 因为 hoverRow 属性不是tableData原有的 则直接修改无效  所以两次深拷贝重新赋值
      let Arr = JSON.parse(JSON.stringify(this.form.limitSameCouponReagio));
      for (let index = 0; index < Arr.length; index++) {
        const element = Arr[index];
        if (eleIndex == index) {
          element["hoverRow"] = true;
        } else {
          element["hoverRow"] = false;
        }
      }
      this.form.limitSameCouponReagio = JSON.parse(JSON.stringify(Arr));
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      for (let index = 0; index < this.form.limitSameCouponReagio.length; index++) {
        const element = this.form.limitSameCouponReagio[index];
        element["hoverRow"] = false;
      }
    },
    //表格增加减少一行的方法
    handleTableRow(type, index) {
      if (type === "push") {
        this.form.limitSameCouponReagio.splice(index + 1, 0, {});
      } else {
        if (this.form.limitSameCouponReagio.length <= 1) {
          this.$set(this.form.limitSameCouponReagio[index], "money", "");
          this.$set(this.form.limitSameCouponReagio[index], "limitCouponQty", "");
          return;
        }
        this.form.limitSameCouponReagio.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-radio,
.el-checkbox {
  color: #333;
}
::v-deep .card {
  margin: 6px 10px 0 10px;
}
.discount-scheme-detail {
  background-color: #eaeaea;
  // padding: 40px 0;
  // padding-bottom: 10px;
  padding: 40px 10px 4px 10px;
  //表单文本显示(向左对齐)
  ::v-deep.el-form-item__label {
    text-align: left !important;
  }
  .content {
    // height: 40px !important;
  }
  //基本信息区域
  .basicInfo {
    padding: 20px;
    //基本信息顶部
    .basicInfoTop {
      display: flex;
      height: 500px;
      //左边优惠券换底色
      .left {
        width: 600px;
        height: 100%;
        background-color: #f2f2f2;
        padding: 10px 20px;
        //优惠券
        .imgTop {
          height: 420px;
          display: flex;
          justify-content: center;
          align-items: center;
          //优惠券样式
          .discountImg {
            display: flex;
            width: 370px;
            height: 128px;
            margin: 0 10px;
            box-shadow: 5px 10px 5px #ededf1;
            border-radius: 4px;
            .imgLeft {
              margin: 15px;
              width: 261px;
              padding: 5px 0;
              background-image: url("~@/assets/images/SpecialScheme.png");
              .content {
                height: 40px;
                div {
                  font-size: 20px;
                  padding: 5px 10px;
                  &.tag {
                    color: #cebcbf;
                    font-size: 12px;
                  }
                }
              }
            }
            .imgRight {
              width: 60px;
              font-size: 25px;
              font-weight: 700;
              color: #fff;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              text-align: justify;
              text-align-last: justify;
            }
          }
        }
        //选择底色
        .colorBase {
          height: 60px;
          background-color: #fff;
          padding: 10px 30px;
          .colorLeft {
            width: 40px;
            font-size: 12px;
            margin-right: 10px;
          }
          //颜色选择
          .colorRight {
            display: flex;
            .coloritem {
              margin: 0 5px;
              width: 35px;
              height: 35px;
              cursor: pointer;
            }
          }
        }
        //颜色区域
        .lightGreen {
          background-color: #55bc47;
        }
        .darkGreen {
          background-color: #10ac61;
        }
        .cambridgeBlue {
          background-color: #34a3de;
        }
        .darkBlue {
          background-color: #338df7;
        }
        .purple {
          background-color: #9158c9;
        }
        .brown {
          background-color: #df9b33;
        }
        .yellow {
          background-color: #ecac16;
        }
        .orange {
          background-color: #f9861e;
        }
        .reddish {
          background-color: #e85535;
        }
        .red {
          background-color: #d53e36;
        }
      }
      .right {
        width: 750px;
        padding: 0 20px;
        .el-select,
        .el-input {
          width: 350px;
          //面值文本框宽度
          &.faceValue {
            width: 200px;
          }
        }
        //使用门槛
        .threshold {
          display: flex;
          ::v-deep.el-form-item__content {
            display: flex !important;
            align-items: center !important;
            height: 36px !important;
          }
          //门槛金额
          .thresholdAmount {
            ::v-deep.el-form-item__content {
              margin-left: 10px !important;
              .el-input {
                width: 120px;
              }
            }
          }
          .text {
            margin-left: 5px;
            margin-top: 7px;
          }
        }
        //提示
        .tag {
          font-size: 12px;
          margin-top: 10px;
          margin-left: 5px;
        }
        //特殊领用时间
        .specialTime {
          display: flex;
          div {
            margin: 0 5px;
          }
          .el-checkbox {
            margin-right: 20px;
          }
          .el-input {
            width: 80px;
          }
        }
      }
    }
    //基本信息底部
    .basicInfoBottom {
      padding: 10px 0;
      //每一行的间距
      .el-form-item {
        margin-bottom: 10px;
      }
      //会员生日下拉框宽度
      .selectVipBD {
        margin-left: 5px;
        width: 140px;
      }
      //抵扣商品
      .deductionGoodsType {
        //文本框宽度
        .el-input {
          width: 100px;
          margin: 0 5px;
        }
      }
      //抵扣商品禁用文本框
      .deductionGoodsTypeDis {
        width: 100px;
        margin: 0 5px;
      }
    }
  }
  //领取限制区域
  .getRestrict {
    padding: 20px;
    .el-input {
      width: 260px; //文本框宽度
    }
    .el-form-item {
      margin-bottom: 10px;
    }
    //限领次数
    .frequency {
      .el-radio {
        width: 100%;
      }
    }
    //次数文本框
    .limitCountDis {
      width: 100px;
      margin-right: 5px;
    }
    .countText {
      margin-top: 8px;
    }
    .num {
      ::v-deep .el-form-item__content {
        margin-left: 0px !important;
      }
      .el-input {
        width: 100px;
        margin-right: 5px;
      }
    }
  }
  //其他限制
  .otherRestrictions {
    padding: 20px;
    .el-form-item {
      margin-bottom: 0px;
    }
    //限制数量
    .limitQty {
      margin-right: 5px;
    }

    //使表格校验时显示，校验通过时隐藏
    ::v-deep .el-form-item {
      padding: 0;
      margin: 0;
    }

    ::v-deep .el-form-item__error {
      position: static;
    }
  }
  .inputW100 {
    width: 100px;
    margin-right: 10px;
    margin-left: 10px;
  }
}
</style>
