<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { getPaperMarketingCouponBookList } from "@/api/marketing/coupon/case";

export default {
  name: "CouponDetail",
  components: { TablePage },
  data() {
    return {
      options: {
        uuid: "705dbb1b-29b5-f1dd-321a-9c0806a258d9",
        mutiSelect: false,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        exportOption: {
          exportApi: "couponDetail",
          exportName: "优惠券明细记录",
        },
        getListApi: getPaperMarketingCouponBookList,
        body: {},
        listNo: true,
        title: "单据信息",
        check: [],
        rowKey: "couponId",
        search: [],
        buttons: [],
        columns: [
          {
            prop: "couponCaseNo",
            label: "优惠券方案编号",
            minWidth: 200,
          },
          {
            prop: "couponCaseName",
            label: "优惠券方案名称",
            minWidth: 155,
          },
          {
            prop: "couponNo",
            label: "优惠券号",
            minWidth: 120,
          },
          {
            prop: "couponPwd",
            label: "优惠券卡密",
            minWidth: 120,
          },
          {
            prop: "couponDesc",
            label: "优惠券内容",
            minWidth: 160,
          },
          {
            prop: "vipName",
            label: "会员名称",
            minWidth: 160,
          },
          {
            prop: "vipNo",
            label: "会员卡号",
            minWidth: 160,
          },
          {
            prop: "tel",
            label: "手机号",
            minWidth: 160,
          },
          {
            prop: "couponCaseTypeName",
            label: "优惠券类型",
            minWidth: 160,
          },
          {
            prop: "takeOutTypeName",
            label: "发放方式",
            minWidth: 160,
          },
          {
            prop: "couponStatusName",
            label: "优惠券状态",
            minWidth: 160,
          },
          {
            prop: "drawVipTime",
            label: "领用时间",
            minWidth: 160,
          },
          {
            prop: "sourceTypeName",
            label: "来源",
            minWidth: 160,
          },
          {
            prop: "validBegTime",
            label: "生效时间",
            minWidth: 160,
          },
          {
            prop: "validEndTime",
            label: "失效时间",
            minWidth: 160,
          },
          {
            prop: "remindAccountTime",
            label: "提醒到账时间",
            minWidth: 160,
          },
          {
            prop: "remindExpireTime",
            label: "提醒到期时间",
            minWidth: 160,
          },
        ],
        list: [],
      },
    };
  },
  created() {
    if (this.$route.query.addLogId && this.$route.query.couponCaseId) {
      this.options.defaultBody = {
        addLogId: this.$route.query.addLogId,
        couponCaseId: this.$route.query.couponCaseId,
      };
    }
  },
};
</script>
<style lang="scss" scoped></style>
