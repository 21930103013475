var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "instore-detail",
    },
    [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c(
            "topOperatingButton",
            {
              attrs: {
                disabled: _vm.disabled,
                showAudit: _vm.showAudit,
                isAuditBillBtn: false,
                isExamineBtn: _vm.form.billStatus == "0",
                isAddBtn: _vm.showAdd,
                id: "topOperatingButton",
              },
              on: {
                submitForm: function ($event) {
                  return _vm.handleSubmit(false)
                },
                addBill: function ($event) {
                  return _vm.handleSubmit(true)
                },
                auditBill: _vm.changeBillStatus,
                handleAdd: _vm.handleAdd,
                getQuit: _vm.getQuit,
              },
            },
            [
              _c(
                "template",
                { slot: "specialDiyBtn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-remove-outline",
                        size: "mini",
                        disabled: _vm.single,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeBillStatus("关闭")
                        },
                      },
                    },
                    [_vm._v("终止单据 ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "discount-scheme-detail",
              attrs: { "element-loading-text": "数据加载中" },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "ken-detail-form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "90px",
                    "label-position": "right",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "cardTitleCom",
                    {
                      attrs: {
                        cardTitle: "基本信息",
                        billStatus: _vm.form.billStatus,
                        id: "basicInformation",
                      },
                    },
                    [
                      _c("template", { slot: "cardContent" }, [
                        _c(
                          "div",
                          { staticClass: "x-f marT10" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "单据编号", prop: "billNo" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "单据编号",
                                    disabled: "",
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.form.billNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "billNo", $$v)
                                    },
                                    expression: "form.billNo",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "单据日期", prop: "billDate" },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "date",
                                    placeholder: "选择日期",
                                    disabled: _vm.disabled,
                                    "value-format": "yyyy-MM-dd",
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.form.billDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "billDate", $$v)
                                    },
                                    expression: "form.billDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "销售人员",
                                  prop: "saleEmployeeId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      disabled: _vm.disabled,
                                      filterable: "",
                                      size: "mini",
                                      remote: "",
                                      "reserve-keyword": "",
                                    },
                                    model: {
                                      value: _vm.form.saleEmployeeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "saleEmployeeId",
                                          $$v
                                        )
                                      },
                                      expression: "form.saleEmployeeId",
                                    },
                                  },
                                  _vm._l(_vm.employeeData, function (item) {
                                    return _c("el-option", {
                                      key: item.employeeId,
                                      attrs: {
                                        label: item.employeeName,
                                        value: item.employeeId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "收款方式", prop: "payModeId" },
                              },
                              [
                                _c("SelectLocal", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    option: {
                                      data: _vm.playList,
                                      value: "payModeId",
                                      label: "payModeName",
                                    },
                                  },
                                  model: {
                                    value: _vm.form.payModeId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "payModeId", $$v)
                                    },
                                    expression: "form.payModeId",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "预收金额", prop: "payMoney" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    placeholder: "预收金额",
                                    size: "mini",
                                  },
                                  on: {
                                    input: function ($event) {
                                      _vm.form.payMoney =
                                        _vm.form.payMoney.replace(
                                          /[^0-9.]/g,
                                          ""
                                        )
                                    },
                                  },
                                  model: {
                                    value: _vm.form.payMoney,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "payMoney", $$v)
                                    },
                                    expression: "form.payMoney",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "x-f" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "客户", prop: "partnerId" } },
                              [
                                _c("SelectFrame", {
                                  ref: "selectFrame",
                                  attrs: {
                                    tableData: _vm.customerData,
                                    disabled: _vm.disabled || _vm.sourceStatus,
                                    router: "/partner/customer/newCustomers",
                                    normalizer: _vm.normalizerCus,
                                    size: "mini",
                                  },
                                  on: {
                                    getPitchId: function ($event) {
                                      return _vm.getDiyselectId(
                                        $event,
                                        "partnerId",
                                        "form",
                                        "客户"
                                      )
                                    },
                                    click: function ($event) {
                                      return _vm.$router.push({
                                        path: "/goods/partner/customer",
                                      })
                                    },
                                  },
                                  model: {
                                    value: _vm.form.partnerName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "partnerName", $$v)
                                    },
                                    expression: "form.partnerName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "cardTitleCom",
                    {
                      staticClass: "headGoodsTable",
                      attrs: { cardTitle: "商品信息" },
                    },
                    [
                      _c("template", { slot: "rightCardTitle" }, [
                        _c("div", { staticClass: "marR10" }),
                      ]),
                      _c("template", { slot: "cardContent" }, [
                        _c(
                          "div",
                          { staticClass: "instore-table" },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c("keyBoardTable", {
                                      key: _vm.tableKey,
                                      ref: "myTable",
                                      attrs: {
                                        isDisabled: _vm.disabled,
                                        isCreatedInit: true,
                                        tableHeight: "450px",
                                        isShowCheckbox: true,
                                        tableColumns: _vm.originColumns,
                                        tableColumnsCopy: _vm.originColumnsCopy,
                                      },
                                      on: {
                                        handleSelectionChange:
                                          _vm.handleSelectionChange,
                                        handleTableBlur: _vm.handleTableBlur,
                                        handleTableInput: _vm.handleTableInput,
                                        handleClickDefineInputIcon:
                                          _vm.handleClickDefineInputIcon,
                                        handleTableSelectChange:
                                          _vm.handleTableSelectChange,
                                        handleSyncTableData:
                                          _vm.handleSyncTableData,
                                        handleTableCallback:
                                          _vm.handleTableCallback,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "instore-footer" },
                    [
                      _c("accessoryUpload", {
                        attrs: {
                          listType: "img",
                          disabled: _vm.disabled,
                          fileList: _vm.form.fileItems,
                        },
                        on: {
                          getFileItems: _vm.getFileItemsData,
                          delFileItems: _vm.delFileItems,
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "right-remark" },
                        [
                          _c("span", [_vm._v(" 单据备注:")]),
                          _c("el-input", {
                            staticClass: "input",
                            attrs: {
                              placeholder: "备注",
                              disabled: _vm.disabled,
                              type: "textarea",
                              rows: 3,
                            },
                            model: {
                              value: _vm.form.billRemark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "billRemark", $$v)
                              },
                              expression: "form.billRemark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SelectCouponStream", {
        attrs: { openDialog: _vm.openDialog },
        on: {
          "update:openDialog": function ($event) {
            _vm.openDialog = $event
          },
          "update:open-dialog": function ($event) {
            _vm.openDialog = $event
          },
          handleConfirmData: _vm.getPitchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }