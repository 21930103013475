<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";

import {
  CouponList,
  isStopCoupon,
  deleteCoupon,
  copyCoupon,
} from "@/api/marketing/coupon/case"; //接口api
export default {
  name: "specialprice",
  components: { TablePage },
  dicts: [
    "coupon_mode", //优惠券形式
    "coupon_case_type", //优惠券类型
  ],
  data() {
    return {
      options: {
        uuid: "a231331f-7439-beba-7fed-6cecf0c66584",
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: CouponList,
        body: {},
        listNo: true, // 序号
        title: "单据信息",
        check: [], // 选中数据
        rowKey: "couponCaseId",
        labelWidth: "100",
        search: [
          {
            label: "创建时间",
            type: "datetimerange",
            model: "",
            noReset: true,
            clearable: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/优惠券方案编号/优惠券方案名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "couponCaseNos", label: "优惠券方案编号" },
              { filter: "couponCaseNames", label: "优惠券方案名称" },
            ],
          },
          this.$select({
            key: "coupon_case_type",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "coupon_mode",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "isStopState",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "enable",
            label: "启用",
            type: "warning",
            btnType: "dropdown",
            other: [
              {
                click: "redoEnable",
                label: "禁用",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "copy",
            label: "复制",
            icon: "el-icon-document-copy",
            type: "success",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "generateCouppon",
            label: "生成优惠券",
            icon: "el-icon-document-copy",
            type: "success",
            disabled: () => !this.options?.check?.length,
          },
          // {
          //   click: "del",
          //   label: "删除",
          //   type: "danger",
          //   icon: "el-icon-delete",
          //   disabled: () => !this.options?.check?.length,
          // },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "couponCaseNo",
            label: "优惠券方案编号",
            type: "link",
            click: "routerLink",
            minWidth: 200,
          },
          {
            prop: "couponCaseName",
            label: "优惠券方案名称",
            minWidth: 155,
          },
          {
            prop: "couponCaseType",
            label: "优惠券类型",
            type: "dict",
            minWidth: 120,
            dict: "coupon_case_type",
          },
          {
            prop: "couponModeName",
            label: "优惠券形式",
            minWidth: 120,
          },
          {
            prop: "isStop",
            label: "启用状态",
            minWidth: 100,
            formatter: (v) => (v ? "禁用" : "启用"),
          },
          {
            prop: "couponDesc",
            label: "优惠券说明",
            minWidth: 160,
          },
          {
            prop: "validBegTime",
            label: "生效时间",
            minWidth: 160,
          },
          {
            prop: "validEndTime",
            label: "失效时间",
            minWidth: 160,
          },
          {
            prop: "couponStatus",
            label: "优惠券状态",
            minWidth: 160,
          },
          {
            prop: "couponCount",
            label: "优惠券发放总数",
            minWidth: 160,
          },
          {
            prop: "scrapCount",
            label: "作废",
            minWidth: 160,
          },
          {
            prop: "drawCount",
            label: "已领",
            minWidth: 160,
          },
          {
            prop: "usedCount",
            label: "已核销",
            minWidth: 160,
          },
          {
            prop: "sellCount",
            label: "销售",
            minWidth: 160,
          },
          {
            prop: "sellBackCount",
            label: "销售退回",
            minWidth: 160,
          },
          {
            prop: "giveCount",
            label: "转增",
            minWidth: 160,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case "routerLink":
          {
            const couponCaseId = row.couponCaseId;
            let couponCaseType = row.couponCaseType;
            let routerName = "";
            if (couponCaseType === "0") routerName = "Voucher";
            if (couponCaseType === "1") routerName = "DiscountCoupon";
            if (couponCaseType === "2") routerName = "DeductionCoupon";
            if (couponCaseType === "3") routerName = "RechargeCoupon";
            this.$router.push({
              name: routerName,
              query: {
                couponCaseId: couponCaseId, //优惠券id
                type: "Update",
              },
            });
          }
          break;
        case "add":
          {
            this.$router.push("/marketing/coupon/case/components/discountScheme");
          }
          break;
        case "enable":
          {
            if (!selectData.length) return;
            this.handleBillStatus("启用", selectData);
          }
          break;
        case "redoEnable":
          {
            if (!selectData.length) return;
            this.handleBillStatus("禁用", selectData);
          }
          break;
        case "copy":
          {
            if (!selectData.length) return;
            this.handleBillStatus("复制", selectData);
          }
          break;
        case "generateCouppon": //couponMode==1
          {
            if (!selectData.length) return;
            if (selectData.length != 1) {
              this.$message.warning("只能选择一笔数据操作");
              return;
            }
            if (selectData[0].couponMode != "1" && selectData[0].couponCaseType != "3") {
              this.$message.warning("请选择纸质优惠券");
              return;
            }
            this.$router.push({
              name: "GenerateCouppon",
              query: {
                couponCaseId: selectData[0].couponCaseId, //优惠券id
                type: "Update",
              },
            });
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            this.handleBillStatus("删除", selectData);
          }
          break;
        default:
          break;
      }
    },
    seniorSearch() {},
    handleBillStatus(command, selectData) {
      let name = "";
      let isStop = "";
      if (command === "复制") {
        name = "复制";
      } else if (command === "启用") {
        name = "启用";
        isStop = false;
      } else if (command === "禁用") {
        name = "禁用";
        isStop = true;
      } else if (command === "删除") {
        name = "删除";
      }
      const couponCaseId = selectData.map((i) => i.couponCaseId);
      const couponCaseNo = selectData.map((i) => i.couponCaseNo);
      // return
      const $this = this;
      this.$modal
        .confirm(`是否确认${name}优惠券方案编号为"` + couponCaseNo + '"?')
        .then(function () {
          if (["启用", "禁用"].includes(name)) {
            isStopCoupon({ couponCaseIds: [...couponCaseId], isStop: isStop }).then(
              (res) => {
                $this.$nextTick(() => {
                  $this.options.check = [];
                });
                setTimeout(() => {
                  $this.$refs.tablePage.getList();
                }, 500);
                if (!isStop) {
                  $this.$modal.msgSuccess("活动方案启用成功");
                } else {
                  $this.$modal.msgSuccess("活动方案禁用成功");
                }
              }
            );
          } else {
            let url = name == "复制" ? copyCoupon : deleteCoupon;
            url([...couponCaseId]).then((res) => {
              $this.$nextTick(() => {
                $this.options.check = [];
              });
              setTimeout(() => {
                $this.$refs.tablePage.getList();
              }, 500);
              $this.$modal.msgSuccess(`${name}成功`);
            });
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
