<!-- 优惠券销售 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { couponSaleList, updateBillStatus } from "@/api/marketing/coupon/sale";
export default {
  name: "room",
  components: { TablePage },
  dicts: ["bill_status"],
  data() {
    return {
      options: {
        uuid: "84dd618a-956b-28be-0723-85047c4d53fc",
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: couponSaleList,
        body: {},
        listNo: true, // 序号
        title: "优惠券销售",
        check: [], // 选中数据
        rowKey: "billId",
        search: [
          {
            label: "创建时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            label: "单据编号",
            seniorSearch: true,
            type: "input",
            model: "",
            filter: "billNo",
          },
          {
            label: "单据状态",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "billStatuss",
            option: {
              remote: this.getDicts,
              remoteBody: "bill_status",
              dataKey: "data",
              label: "dictLabel",
              value: "dictValue",
              filterable: true,
              multiple: true,
            },
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "audit",
            label: "审核",
            alertText: "确认要审核选中方案吗？",
            type: "success",
            icon: "el-icon-document",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "stop",
            label: "终止",
            icon: "el-icon-remove-outline",
            type: "primary",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "routerLink",
            minWidth: 180,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 120,
            formatter: (propValue) => propValue.split(" ")[0],
          },
          {
            prop: "billStatusName",
            label: "单据状态",
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "客户",
            minWidth: 140,
          },
          {
            prop: "saleEmployeeName",
            label: "销售人员",
            minWidth: 160,
          },
          {
            prop: "billMoney",
            label: "单据金额",
            minWidth: 120,
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 120,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 120,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case "routerLink":
          {
            const billId = row.billId;
            this.$router.push({
              name: "CouponSaleDetail",
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "add":
          {
            this.$router.push({
              name: "CouponSaleDetail",
              query: {
                type: "Add",
              },
            });
          }
          break;
        case "audit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("审核", selectData);
          }
          break;
        case "redoAudit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("反审核", selectData);
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            this.handleBillStatus("删除", selectData);
          }
          break;
        case "stop":
          {
            if (!selectData.length) return;
            this.handleBillStatus("关闭", selectData);
          }
          break;
        default:
          break;
      }
    },
    seniorSearch() {},
    handleBillStatus(command, selectData, url, BillType, type) {
      let name = ""; //要执行的状态名字
      let billStatus = ""; //要执行的状态数字
      if (command === "反审核") {
        name = "反审核";
        billStatus = "0";
      } else {
        if (command === "审核") {
          name = "审核";
          billStatus = "2";
        } else if (command === "删除") {
          name = "删除";
          billStatus = "3";
        } else if (command === "关闭") {
          name = "关闭";
          billStatus = "4";
        }
      }
      const billId = selectData.map((i) => i.billId);
      const billNo = selectData.map((i) => i.billNo);
      let str = billNo.length > 2 ? `${billNo[0]},${billNo[1]}...` : billNo;
      const $this = this;
      this.$modal
        .confirm(`是否确认${name}单据编号为"` + str + '"的单据项?')
        .then(function () {
          return updateBillStatus([...billId], billStatus).then(() => {
            $this.$nextTick(() => {
              $this.options.check = [];
            });
            $this.$modal.msgSuccess(`${name}成功`);
            setTimeout(() => {
              $this.$refs.tablePage.getList();
            }, 500);
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
